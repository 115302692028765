<template>
    <div>
        <div v-show="isShow">
            <van-nav-bar
            title="上传附件"
            left-arrow
            @click-left="onClickLeft"
            />
            <div class="titleLineDiv" @click="toUploder('发票')">
                <div>
                    发票
                </div>
                <img src="../../assets/image/loder/right.png" alt="">
            </div>
            <div class="titleLineDiv" @click="toUploder('水单表')">
                <div>
                    水单表 
                </div>
                <img src="../../assets/image/loder/right.png" alt="">
            </div>
            <div class="titleLineDiv" @click="toUploder('核稽表')">
                <div>
                    核稽表
                </div>
                <img src="../../assets/image/loder/right.png" alt="">
            </div>
        </div>
        
        <router-view></router-view>
    </div>
</template>
<script>
    export default{
        name:'UpLoderList',
        data(){
            return{
                isShow:true
            }
        },
        methods:{
            onClickLeft(){
                this.$router.back()
            },
            toUploder(name){
                let id = this.$route.query.id;
                this.$router.push({
                    path: "uploderList/uploderDetail",
                    query:{
                        type:name,
                        id
                    }
                });
            }, 
        },
        mounted(){
            document.querySelector('body').setAttribute('style','background-color:#F7F7F7')
        },
        beforeMount(){
            if (this.$route.path.indexOf("uploderDetail") != -1) {
                this.isShow = false;
            }else{
                this.isShow = true;
            }
        },

       
    }
</script>
<style scoped>
.titleLineDiv{
    margin-top: 10px;
    height: 100px;
    background: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleLineDiv div{
    margin-left:26px;
    height: 34px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
}
.titleLineDiv img{
    width: 14px;
    margin-right: 67px;
}
</style>
